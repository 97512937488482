import React from 'react';
import './index.css';
import { Link } from 'react-router-dom';

class SplashScreen extends React.Component {

	render() {
		return (
			<div id="splashcontainer">
				<div id="splashcontent">
					<h1 className="title">
						<span>The Wedding of<br/></span>
						Erin &amp; Nick
					</h1>
				</div>
				<div id="splashbuttons">
					<button tabIndex="-1" className="save-the-date"><Link to={'save-the-date'}>Save the Date</Link></button>
					<button tabIndex="-1" className="the-big-day"><Link to={'the-big-day'}>The Big Day</Link></button>
				</div>
			</div>
		);
	}
}

export default SplashScreen;