import React, { Component } from "react";
import RSVP from "./RSVP";
import AnchorLink from './AnchorLink';
import {
  getInviteDetails
} from "../../firebaseConfig";

export class TheBigDayContent extends Component {
  constructor(props){
    super(props);
    this.state = {
      inviteObject: getInviteDetails(localStorage.getItem("rsvp-code"))
    }
  }
  render() {
    const { inviteObject } = this.state;
    return (
        <div className="content-container">
        <RSVP />

        <span> January 2020 </span>

        {
          inviteObject && inviteObject.isInvitedToRehearsal && (
            <section className="day">
              <div className="row-w-meta">
                <div className="meta">
                  <span className="big">17</span>
                </div>
                <h1> Rehearsal & Dinner </h1>
              </div>
              <div className="row-w-meta empty">
                <div className="meta">
                  <span>PM</span>
                </div>
              </div>
              <div className="row-w-meta">
                <div className="meta">
                  <span>5:00</span>
                </div>
                <h2>Rehearsal</h2>
                <p>
                  St. Paul's Catholic Church <br />
                  909 Rennie Ave <br />
                  Richmond, VA 23227
                </p>
              </div>
              <div className="row-w-meta">
                <div className="meta">
                  <span>6:00</span>
                </div>
                <h2>Dinner</h2>
                <p>
                  Castleburg Brewery and Taproom <br />
                  1626 Ownby Ln <br />
                  Richmond, VA 23220
                </p>
              </div>
            </section>
          )
        }
        <section className="day">
          <div className="row-w-meta">
            <div className="meta">
              <span className="big">18</span>
            </div>
            <h1> The Big Day </h1>
          </div>
          <div className="row-w-meta">
            <div className="meta">
              <span>AM</span>
            </div>
            {
              (inviteObject && inviteObject.isInvitedToRehearsal) ? (
                <p>Enjoy your morning in Richmond!</p>
              ) : (
                <p>Arrive, check-in, and get settled.</p>
              )
            }
            <div className="links">
              <AnchorLink anchor="accommodations">Accommodations</AnchorLink>
              <AnchorLink anchor="transportation">Transportation</AnchorLink>
              <AnchorLink anchor="entertainment">Entertainment</AnchorLink>
            </div>
          </div>
          <div className="row-w-meta empty">
            <div className="meta">
              <span>PM</span>
            </div>
          </div>
          <div className="row-w-meta">
            <div className="meta">
              <span>2:00</span>
            </div>
            <h2>Ceremony</h2>
            <p>
              St. Paul's Catholic Church <br />
              909 Rennie Ave <br />
              Richmond, VA 23227
            </p>
          </div>
          <div className="row-w-meta">
            <div className="meta">
              <span>{'3:00'}</span>
            </div>
            <p>
              Ceremony ends; explore Richmond.
            </p>
            <div className="links"><AnchorLink anchor="entertainment">Entertainment</AnchorLink></div>
          </div>
          <div className="row-w-meta">
            <div className="meta">
              <span>6:00</span>
            </div>
            <h2>Reception</h2>
            <p>
              Science Museum of Virginia <br/>
              2500 W Broad St <br/>
              Richmond, VA 23220
            </p>
          </div>
        </section>
        <section className="day">
          <div className="row-w-meta">
            <div className="meta">
              <span className="big">19</span>
            </div>
            <h1>Launch Party</h1>
          </div>
          <div className="row-w-meta empty">
            <div className="meta">
              <span>AM</span>
            </div>
          </div>
          <div className="row-w-meta">
            <div className="meta">
              <span>10:30</span>
            </div>
            <h2>The First Day as Mr. & Mrs.</h2>
            <p>
              Urban Roost <br />
              3023 W Marshall St <br />
              Richmond, VA 23230
            </p>
          </div>
          <div className="row-w-meta empty">
            <div className="meta">
              <span>PM</span>
            </div>
          </div>
          <div className="row-w-meta">
            <div className="meta">
              <span>2:30</span>
            </div>
            <p>
              Enjoy Richmond! Erin & Nick will be staying in <a target="_blank" rel="noopener noreferrer" className="inline" href="https://www.scottsaddition.com">Scott’s Addition</a> until they get tired of hanging out with everyone.
            </p>
            <div className="links"><AnchorLink anchor="entertainment">Entertainment</AnchorLink></div>
          </div>
        </section>
        <section id="accommodations">
          <h1>Accommodations</h1>
          <p>Due to the lower rates found online for Richmond hotels in January, and the fact that we will not have any events hosted at a hotel, we have chosen not to secure a room block. Instead, please find recommended hotel options located both east (downtown) and west of our venues.</p>
          <p>In addition to these hotels, we highly recommend checking out <a target="_blank" rel="noopener noreferrer" className="inline" href="https://www.airbnb.com/s/Richmond--VA">Airbnb</a> as there are a ton of great options in Richmond.</p>
          <p>If you want to go the Airbnb route, it’s especially advised to book early!</p>
          <h2>Recommended Hotels:</h2>
          <ul>
            <li>
              <span className="name">Delta Hotels by Marriott Richmond Downtown, Richmond</span><br/>
              <span className="additional-info">555 E Canal Street, Richmond, VA, 23219; (804) 788-0900</span>
              <span className="additional-info"></span>
            </li>
            <li>
              <span className="name">Hilton Richmond Downtown, Richmond</span><br/>
              <span className="additional-info">501 E Broad St, Richmond, VA, 23219; (804) 344-4300</span>
            </li>
            <li>
              <span className="name">Courtyard by Marriott Richmond West, Richmond</span><br/>
              <span className="additional-info">6400 W Broad St, Richmond, VA, 23230; (804) 282-1881</span>
            </li>
            <li>
              <span className="name">Holiday Inn Richmond - Midtown</span><br/>
              <span className="additional-info">2000 Staples Mill Rd, Richmond, VA, 23230; (804) 359-6061</span>
            </li>
          </ul>   
        </section>
        <section id="transportation">
          <h1>  Transportation</h1>
          <p>Richmond is conveniently located right off of I-95. If you are hesitant to travel on I-95 to get to Richmond we also highly recommend the train. There is an Amtrak station very centrally located to the hotels and event venues for the weekend.</p>
          <p>Transportation to and from events for the weekend will be up to the guests. You can opt to drive or take advantage of Richmond’s new bus service, the Pulse, which travels up and down Broad Street or make use of Uber/Lyft.</p>
          <p>For drivers, there will be ample parking both at our ceremony and reception venues. Cars can be left overnight in the Science Museum Parking lot.</p>
        </section>
        <section id="entertainment">
          <h1>Entertainment</h1>
          <div className="blocks">
            <div>
              <h2>Eat</h2>
              <ul>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="https://www.kubakuba.info">Kuba Kuba</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="https://www.properpieco.com">Proper Pie</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="https://www.buzandneds.com">Buzz n' Neds</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="https://stellasrichmond.com">Stella's</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="https://www.perlysrichmond.com">Perly's</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="http://ilovestickyrice.com/">Sticky Rice</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="https://tarrantscaferva.com/">Tarrant's</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="more" href="https://www.visitrichmondva.com/restaurants/local-flavor/">plenty more...</a></li>
              </ul>
            </div>
            <div>
              <h2>Beverages</h2>
              <ul>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="https://castleburgbrewery.com">Castleburg Brewery</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="https://www.isleybrewingcompany.com">Isley Brewing Co.</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="https://triplecrossing.com/">Triple Crossing Brewing</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="http://ardentcraftales.com/">Ardent Craft Ales</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="https://www.buskeycider.com/">Buskey Cider</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="more" href="https://www.visitrichmondva.com/drink/craft-beer-cider/breweries/">plenty more...</a></li>
              </ul>
            </div>
            <div>
              <h2>Games</h2>
              <ul>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="https://topgolf.com/us/richmond/">Topgolf</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="https://www.rivercityroll.com/">River City Roll</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="https://thecircuitarcadebar.com/">The Circuit</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="http://www.greenleafspoolroom.com/">Greenleaf's Pool Room</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="https://www.hotelgreene.com/">Hotel Greene (mini-golf)</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="https://www.bingorva.com">Bingo Beer Co.</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="more" href="https://www.visitrichmondva.com/things-to-do/family-fun/kids-activities/">plenty more...</a></li>
              </ul>
            </div>
            <div>
              <h2>Walks</h2>
              <ul>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="https://www.visitrichmondva.com/coupon/highlights-of-hollywood-cemetery-walking-tour/ot_4240/">Hollywood Cemetary</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="http://www.richmondgov.com/parks/parkbyrd.aspx">Byrd Park</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="https://www.visitrichmondva.com/listings/bryan-park/1069/">Bryan Park</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="http://www.brownsisland.com">Brown's Island</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="http://www.rvariverfront.com">Canal Walk</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="https://www.tripadvisor.com/Attraction_Review-g60893-d10067712-Reviews-Belle_Isle-Richmond_Virginia.html">Belle Isle</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="https://www.lewisginter.org/event/half-price/">Lewis Ginter Botanical Gardens</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="more" href="https://www.yelp.com/search?find_desc=walking+trails&find_loc=Richmond%2C+VA">plenty more...</a></li>
              </ul>
            </div>
            <div>
              <h2>Museums</h2>
              <ul>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="https://www.smv.org/">Science Museum of Virginia</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="https://www.childrensmuseumofrichmond.org/">Children's Museum of Richmond</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="https://www.poemuseum.org/">Edgar Allen Poe Museum</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="https://www.vmfa.museum/">Virginia Museum of Fine Arts</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="normal" href="https://www.virginiahistory.org/">Virginia Museum of History & Culture</a></li>
                <li><a target="_blank" rel="noopener noreferrer" className="more" href="https://www.visitrichmondva.com/things-to-do/attractions-museums/museums/">plenty more...</a></li>
              </ul>
            </div>

          </div>
        </section>
      </div>
    );
  }
}

export default TheBigDayContent;
