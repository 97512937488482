import React from 'react';
import './App.css';
import SplashScreen from './components/splash-screen';
import SaveTheDate from './components/save-the-date';
import TheBigDay from './components/the-big-day';
import './App.css';

const App = () => {
  return (
    <div id="total-container">
      <SplashScreen />
      <SaveTheDate />
      <TheBigDay />
    </div>
  );
}

export default App;
