import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import './index.css';

class SaveTheDate extends React.Component {
  render() {
    let show = false;
    if(this.props.location.pathname.match(/save-the-date/)){
      show = true;
    }
      
    return (
      <div id="save-the-date" className={show ? '' : 'hidden'}>
        <div className='content-container'>
          <Link to={'/'} className='close'>close</Link>
        </div>
        <div className='content-container'>
          
          <div className="content">
            <h1>Save the Date</h1>

            <h2>About Us</h2>
            <p>Erin and Nick officially met in a computer science course, Simulations, taught by Dr. Barry Lawson during the spring of 2014. After a semester of unprecedented attendance to the course, Nick asked Erin to be partners on the final exam, not yet knowing he'd ask her to be partners for life only five years later.</p>
            <p>Over the next year they built a formidable friendship built on a love of sports, math &amp; technology, dogs &amp; beer. By 2015 they were officially an item, and by that fall had adopted Sandy, the beautiful pup on our Save the Date. </p>

            <h2>When &amp; Where</h2>
            <p>At 2:00pm on January 18th, 2020, the ceremony will be held at St. Paul's Catholic Church with a reception later that evening at the Science Museum of Virignia.</p>
            <p>Please visit <Link to={'/the-big-day'}>The Big Day</Link> for more details.</p>

            <h2>Registry</h2>
            <div className="registry-container">
              <a href="http://zola.com/registry/erinick" rel="noopener noreferrer" target="_blank">
                <img src="/img/zola_logo.png" alt="Zola Registry" />
              </a>

              <a href="https://www.macys.com/wgl/registry/guest/7083189" rel="noopener noreferrer" target="_blank">
                <img src="/img/macys_logo.png" alt="Macy's Registry" />
              </a>

              <a href="https://crateandbarrel.com/gift-registry/erin-geoghan-and-nick-daniel/r5998320" rel="noopener noreferrer" target="_blank">
                <img src="/img/cb_logo.png" alt="Crate and Barrel Registry" />
              </a>
              
              <a href="https://www.cutco.com/registry/5rv7y" rel="noopener noreferrer" target="_blank">
                <img src="/img/cutco_logo.svg" alt="Cutco Registry" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SaveTheDate);