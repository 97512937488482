import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// import InvitesJSON from './invites.json'

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export const firebaseObj = firebase;
const firestore = firebase.firestore();
export const invitesRef = firestore.collection("invites");
export const responsesRef = firestore.collection("responses");

export const checkAvailability = async (code) => {
  if(!code || code === ''){
    return false;
  }
  let inviteDoc = await invitesRef.doc(code).get();
  let responseDoc = await responsesRef.doc(code).get();

  if(responseDoc.data()){
    localStorage.setItem('rsvpObject', JSON.stringify(responseDoc.data()));
  }
  if(inviteDoc.data()){
    localStorage.setItem('inviteObject', JSON.stringify(inviteDoc.data()));
    return true;
  }

  return false;
}

export const checkHasResponsed = async (code) => {
  if(!code || code === ''){
    return false;
  }

  let rsvpDoc = await responsesRef.doc(code).get();
  if(rsvpDoc.data()){
    localStorage.setItem('rsvpObject', JSON.stringify(rsvpDoc.data()));
    return true;
  }

  return false;
}

export const getInviteDetails = () => {
  return JSON.parse(localStorage.getItem('inviteObject'));
}

export const getRSVPDetails = () => {
  return JSON.parse(localStorage.getItem('rsvpObject'));
}

export const submitRSVP = async (code, data) => {
  try {
    await responsesRef.doc(code).set(data);
    localStorage.setItem('rsvpObject', JSON.stringify(data));
    return true;
  } catch {
    return false;
  }
}

// const resetInvites = () => {
//   try {
//     Object.keys(InvitesJSON).forEach(rsvpCode => {
//       invitesRef.doc(rsvpCode).set(InvitesJSON[rsvpCode]);
//     });
//   } catch {
//     console.log('error resetting invites')
//   }
// }
// resetInvites();