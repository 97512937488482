import React, { Component } from "react";
import RSVPGuest from "./RSVPGuest";
import "./RSVP.css";
import {
  getInviteDetails,
  getRSVPDetails,
  submitRSVP
} from "../../firebaseConfig";
import LoadingSvg from "./LoadingSvg";

export class RSVP extends Component {
  constructor(props) {
    super(props);

    const inviteObject = getInviteDetails(localStorage.getItem("rsvp-code"));
    let rsvpObject = getRSVPDetails(localStorage.getItem("rsvp-code"));

    // if (inviteObject && !rsvpObject) {
    //   let newRsvpObject = {};
    //   inviteObject.guests.forEach(guest => {
    //     newRsvpObject[guest.name]= {};
    //   });
    //   rsvpObject = newRsvpObject;
    // }

    this.state = {
      rsvpCode: localStorage.getItem("rsvp-code"),
      inviteObject,
      rsvpObject,
      loading: false,
      showSubmittedRSVP: false
    };
  }

  render() {
    const {
      rsvpCode,
      inviteObject,
      rsvpObject,
      loading,
      showSubmittedRSVP
    } = this.state;
    if (!inviteObject) {
      return null;
    }

    if (loading) {
      return (
        <section id="rsvp-container">
          <div className="loading">
            <LoadingSvg />
          </div>
        </section>
      );
    }

    let hasBeenSubmitted = false;
    if (rsvpObject && rsvpObject !== {}) {
      hasBeenSubmitted = true;
    }

    return (
      <section id="rsvp-container">
        {hasBeenSubmitted ? (
          <>
            <div className="top responded">
              <h1>Thank you for RSVPing!</h1>
              <button
                onClick={event => {
                  event.preventDefault();
                  this.setState({
                    showSubmittedRSVP: !showSubmittedRSVP
                  });
                }}
                onKeyDown={event => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    this.setState({
                      showSubmittedRSVP: !showSubmittedRSVP
                    });
                  }
                }}
              >
                {showSubmittedRSVP ? "Hide" : "View"}
              </button>
            </div>
            <p style={{marginBottom: 0}}>Please check back in the week of the wedding to ensure you have the latest information.</p>

            {showSubmittedRSVP && (
              <div className="view-rsvp">
                {Object.keys(rsvpObject).map(key => {
                  return (
                    <div key={key} className="rsvp-guest">
                      <span>{key}</span>
                      <ul>
                        <li>
                          Attending Saturday:{" "}
                          {rsvpObject[key].isAttendingSaturday ? "Yes" : "No"}
                        </li>
                        <li>
                          Attending Sunday:{" "}
                          {rsvpObject[key].isAttendingSunday ? "Yes" : "No"}
                        </li>
                        {inviteObject.isInvitedToRehearsal && (
                          <li>
                            Attending Rehearsal:{" "}
                            {rsvpObject[key].isAttendingRehearsal
                              ? "Yes"
                              : "No"}
                          </li>
                        )}
                        {rsvpObject[key].isKidsMeal && (
                          <li>
                            Kid's meal*:{" "}
                            {rsvpObject[key].isKidsMeal
                              ? "Yes"
                              : "No"}
                          </li>
                        )}
                        <li>
                          Dietary Notes:{" "}
                          {rsvpObject[key].dietaryNotes
                            ? rsvpObject[key].dietaryNotes
                            : "None"}
                        </li>
                      </ul>
                    </div>
                  );
                })}
                <p>Need to make changes? Please reach out to Peter and Jo Ann Geoghan at <a href="tel:301-325-1924">(301) 325-1924</a>.</p>

              </div>
            )}
          </>
        ) : (
          <form>
            <div className="top rsvp-heading">
              <h1>Your RSVP</h1>
              <span className="code-details">{`CODE: ${rsvpCode}`}</span>
            </div>
            {inviteObject.guests.map((guest, index) => (
              <RSVPGuest
                key={guest.name}
                guest={guest}
                firstGuest={index === 0}
                showRehearsal={inviteObject.isInvitedToRehearsal}
                updateParentState={state => {
                  this.setState({
                    rsvpPayload: {
                      ...this.state.rsvpPayload,
                      [guest.name]: state
                    }
                  });
                }}
              />
            ))}
            {
              inviteObject.guests.reduce((result, next) => result || next.hasKidsMealOption, false) && (
                <span className="additional-info">*Chicken Fingers, French Fries, Steamed Broccoli</span>
              )
            }
            <div className="bottom">
              <button
                className="submit"
                onClick={event => {
                  event.preventDefault();
                  this.submitRSVP();
                }}
                onKeyDown={event => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    this.submitRSVP();
                  }
                }}
              >
                Submit
              </button>
            </div>
          </form>
        )}
      </section>
    );
  }

  async submitRSVP() {
    this.setState({
      loading: true
    });

    const rsvpResponse = await submitRSVP(
      this.state.rsvpCode,
      this.state.rsvpPayload || {}
    );
    if (rsvpResponse) {
      let objectToView = {};
      this.state.inviteObject.guests.forEach(guest => {
        objectToView[guest.name] = {};
      });
      this.setState({
        loading: false,
        rsvpObject: {
          ...objectToView,
          ...getRSVPDetails(this.state.rsvpCode)
        }
      });
    } else {
      this.setState({
        loading: false
      });
    }
  }
}

export default RSVP;
