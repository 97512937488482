import React from "react";
import { withRouter } from "react-router-dom";

const AnchorLink = props => {
  const updateHash = event => {
    event.preventDefault();
    props.history.push(`${props.location.pathname}#${props.anchor}`);
  };

  return (
    <a
      onClick={event => updateHash(event)}
      onKeyDown={event => {
        if (event.key === "Enter") {
          updateHash(event);
        }
      }}
      href={`#${props.anchor}`}
    >
      {props.children}
    </a>
  );
};

export default withRouter(AnchorLink);
