import React, { Component } from "react";
import "./Login.css";
import { checkAvailability } from "../../firebaseConfig";

export class Login extends Component {
  constructor() {
    super();
    this.state = {
      code: ""
    };
  }
  render() {
    return (
      <div id="login-container">
        <img src="/img/lock.svg" alt="Padlock - locked" />
        <input
          id="rsvp-input"
          type="text"
          value={this.state.code}
          placeholder="RSVP Code"
          onChange={event => this.handleChange(event)}
          onKeyDown={event => {
            if (event.key === "Enter") {
              event.preventDefault();
              this.submitCode();
            }
          }}
        />
        <button
          id="login-button"
          onClick={() => this.submitCode()}
          onKeyDown={event => {
            if (event.key === "Enter") {
              event.preventDefault();
              this.submitCode();
            }
          }}
        >
          Enter
        </button>
      </div>
    );
  }

  handleChange(event) {
    this.setState({ code: (event.target.value || '').toLowerCase() });
  }

  async submitCode() {
    this.props.setLoadingVisibility(true);

    const codeResponse = await checkAvailability((this.state.code || '').toLowerCase());

    if(codeResponse){
      localStorage.setItem("rsvp-code", this.state.code);
      this.props.setLoadingVisibility(false);
      this.props.successfulLogin();
    } else {
      this.props.setLoadingVisibility(false);
      this.props.invalidLogin();
    }
  }
}

export default Login;
