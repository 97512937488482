import React, { useState } from "react";

export default function RSVPGuest(props) {
  const { guest } = props;
  const [showDietaryInput, setShowDietaryInput] = useState(false);
  const [dietaryNotes, setDietaryNotes] = useState("");
  const [isAttendingSaturday, setAttendingSaturday] = useState(false);
  const [isAttendingSunday, setAttendingSunday] = useState(false);
  const [isAttendingRehearsal, setAttendingRehearsal] = useState(false);
  const [isNotAttending, setNotAttending] = useState(false);
  const [isKidsMeal, setKidsMeal] = useState(!!props.guest.isKidsMealDefault);

  const updateField = (field, value) => {
    switch (field) {
      case "dietaryNotes":
        setDietaryNotes(value);
        break;
      case "isAttendingSaturday":
        setAttendingSaturday(value);
        break;
      case "isAttendingSunday":
        setAttendingSunday(value);
        break;
      case "isAttendingRehearsal":
        setAttendingRehearsal(value);
        break;
      case "isNotAttending":
        setNotAttending(value);
        break;
      case "isKidsMeal":
        setKidsMeal(value);
        break;
      default:
        console.log("what happened");
    }

    let stateToSendToParent = {
      dietaryNotes,
      isAttendingSaturday,
      isAttendingSunday,
      isAttendingRehearsal,
      isNotAttending,
      isKidsMeal
    };

    stateToSendToParent[field] = value;
    props.updateParentState(stateToSendToParent);
  };

  return (
    <div className="guest">
      <div className="left">
        <span className="guest-name">{guest.name}</span>
        <div className="dietary-restrictions">
          {showDietaryInput ? (
            <>
              <label>Dietary Restrictions</label>
              <input
                aria-label={guest.name + " dietary notes input"}
                type="text"
                onChange={event => {
                  updateField("dietaryNotes", event.target.value);
                }}
                value={dietaryNotes}
              />
            </>
          ) : (
            <button
              className="dietary-opener"
              onClick={() => setShowDietaryInput(true)}
              onKeyDown={event => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  setShowDietaryInput(true);
                }
              }}
            >
              Dietary Restrictions?
            </button>
          )}
        </div>
        {
          guest.hasKidsMealOption === true ? (
            <>
              <label className="checkbox-container kids-meal">
                Kid's Meal*
                <input
                  type="checkbox"
                  checked={isKidsMeal}
                  onChange={() => {
                    updateField("isKidsMeal", !isKidsMeal);
                  }}
                />
                <span className="checkmark"></span>
              </label>
            </>
          ) : null
          }
      </div>
      <div className="right">
        <div className="top">
          {props.firstGuest && <label className="attendance">Attendance</label>}
        </div>
        <div className="saturday">
          <label className={"checkbox-container " + (isNotAttending ? 'disabled ' : '')}>
            Saturday
            <input
              type="checkbox"
              checked={isAttendingSaturday}
              onChange={() => {
                if(isAttendingSaturday){
                  updateField("isAttendingSunday", false);
                }
                updateField("isAttendingSaturday", !isAttendingSaturday);
              }}
            />
            <span className="checkmark"></span>
          </label>
        </div>

        <div className="sunday">
          <label className={"checkbox-container " + ((!isAttendingSaturday || isNotAttending) ? 'disabled ' : '') }>
            Sunday
            <input
              type="checkbox"
              checked={isAttendingSunday}
              onChange={() => {
                if(!isAttendingSaturday){
                  if(isAttendingSunday){
                    updateField("isAttendingSunday", !isAttendingSunday);
                  }
                  return;
                }
                updateField("isAttendingSunday", !isAttendingSunday);
              }}
            />
            <span className="checkmark"></span>
          </label>
        </div>

        {props.showRehearsal && (
          <div className="rehearsal">
            <label className="checkbox-container">
              Rehearsal Dinner
              <input
                type="checkbox"
                checked={isAttendingRehearsal}
                onChange={() => {
                  updateField("isAttendingRehearsal", !isAttendingRehearsal);
                }}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        )}
        {!props.showRehearsal && (
          <div className="not-attending">
            <label className="checkbox-container">
              Unable to attend
              <input
                type="checkbox"
                checked={isNotAttending}
                onChange={() => {
                  if(!isNotAttending){
                    updateField("isAttendingSaturday", false);
                    updateField("isAttendingSunday", false);
                  }
                  updateField("isNotAttending", !isNotAttending);
                }}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        )}
      </div>
    </div>
  );
}
