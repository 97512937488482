import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory();

browserHistory.listen(location => {
  const { hash } = location;
  if (hash !== "") {
    setTimeout(() => {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      const scrollingElem = document.getElementById('main-scroller');
      if (element && scrollingElem) {
        let elementPosition = element.offsetTop;
        scrollingElem.scrollTop = elementPosition;
      }
    }, 0);
  }
});

ReactDOM.render(
  <Router history={browserHistory}>
    <App />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
